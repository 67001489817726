// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { uuid4 } from '@/utils/generators';
import { Icon } from './icon';

export const SharePointIcon = forwardRef((props: IconProps, ref) => {
  const guid = `share-point-icon-${uuid4()}`;

  return (
    <Icon viewBox="0 0 1992.333 1946" {...props} ref={ref} fill="none">
      <circle fill="#036C70" cx="1019.333" cy="556" r="556" />

      <circle fill="#1A9BA1" cx="1482.667" cy="1065.667" r="509.667" />

      <circle fill="#37C6D0" cx="1088.833" cy="1552.167" r="393.833" />

      <path
        opacity="0.1"
        d="M1112,501.79v988.753c-0.23,34.357-21.05,65.222-52.82,78.303
	c-10.116,4.279-20.987,6.484-31.97,6.487H695.463c-0.463-7.877-0.463-15.29-0.463-23.167c-0.154-7.734,0.155-15.47,0.927-23.167
	c8.48-148.106,99.721-278.782,235.837-337.77v-86.18c-302.932-48.005-509.592-332.495-461.587-635.427
	c0.333-2.098,0.677-4.195,1.034-6.289c2.306-15.626,5.556-31.099,9.73-46.333h546.27C1073.964,417.178,1111.822,455.036,1112,501.79
	z"
      />

      <path
        opacity="0.2"
        d="M980.877,463.333H471.21c-51.486,302.386,151.908,589.256,454.293,640.742
	c9.156,1.559,18.35,2.888,27.573,3.986c-143.633,68.11-248.3,261.552-257.196,420.938c-0.771,7.697-1.081,15.433-0.927,23.167
	c0,7.877,0,15.29,0.463,23.167c0.836,15.574,2.85,31.063,6.023,46.333h279.39c34.357-0.23,65.222-21.05,78.303-52.82
	c4.279-10.115,6.485-20.987,6.487-31.97V548.123C1065.443,501.387,1027.613,463.537,980.877,463.333z"
      />

      <path
        opacity="0.2"
        d="M980.877,463.333H471.21c-51.475,302.414,151.95,589.297,454.364,640.773
	c6.186,1.053,12.389,2.001,18.607,2.844c-139,73.021-239.543,266-248.254,422.05h284.95c46.681-0.353,84.437-38.109,84.79-84.79
	V548.123C1065.489,501.369,1027.631,463.511,980.877,463.333z"
      />

      <path
        opacity="0.2"
        d="M934.543,463.333H471.21c-48.606,285.482,130.279,560.404,410.977,631.616
	C775.901,1216.384,710.711,1368.301,695.927,1529h238.617c46.754-0.178,84.612-38.036,84.79-84.79V548.123
	C1019.308,501.306,981.361,463.359,934.543,463.333z"
      />

      <linearGradient
        id={`linear-gradient-1-${guid}`}
        gradientUnits="userSpaceOnUse"
        x1="177.0788"
        y1="1551.0284"
        x2="842.2545"
        y2="398.9716"
        gradientTransform="matrix(1 0 0 -1 0 1948)"
      >
        <stop offset="0" stopColor="#058F92" />

        <stop offset="0.5" stopColor="#038489" />

        <stop offset="1" stopColor="#026D71" />
      </linearGradient>

      <path
        fill={`url(#linear-gradient-1-${guid})`}
        d="M84.929,463.333h849.475c46.905,0,84.929,38.024,84.929,84.929v849.475
	c0,46.905-38.024,84.929-84.929,84.929H84.929c-46.905,0-84.929-38.024-84.929-84.929V548.262
	C0,501.357,38.024,463.333,84.929,463.333z"
      />

      <path
        fill="#FFFFFF"
        d="M379.331,962.621c-19.903-13.202-36.528-30.777-48.604-51.384c-11.701-21.542-17.533-45.781-16.912-70.288
	c-1.042-33.181,10.155-65.586,31.46-91.045c22.388-25.49,51.326-44.366,83.678-54.581c36.871-12.136,75.49-18.116,114.304-17.699
	c51.043-1.865,102.015,5.272,150.583,21.082v106.567c-21.103-12.784-44.088-22.166-68.11-27.8
	c-26.065-6.392-52.81-9.597-79.647-9.545c-28.3-1.039-56.419,4.913-81.871,17.329c-19.65,8.475-32.392,27.807-32.433,49.206
	c-0.08,12.981,4.907,25.481,13.9,34.843c10.622,11.037,23.187,20.021,37.067,26.503c15.444,7.691,38.611,17.916,69.5,30.673
	c3.401,1.075,6.716,2.407,9.915,3.985c30.401,11.881,59.729,26.344,87.663,43.229c21.154,13.043,38.908,30.924,51.801,52.171
	c13.218,24.085,19.625,51.315,18.533,78.767c1.509,34.066-8.913,67.591-29.468,94.798c-20.488,25.012-47.88,43.446-78.767,53.005
	c-36.329,11.387-74.245,16.892-112.312,16.309c-34.154,0.155-68.258-2.635-101.933-8.34c-28.434-4.653-56.182-12.807-82.612-24.279
	v-112.358c25.264,18.043,53.489,31.529,83.4,39.847c29.81,9.289,60.798,14.251,92.018,14.734c28.895,1.83,57.739-4.291,83.4-17.699
	c17.976-10.144,28.909-29.358,28.449-49.994c0.12-14.359-5.56-28.158-15.753-38.271c-12.676-12.444-27.352-22.671-43.414-30.256
	c-18.533-9.267-45.824-21.483-81.871-36.65C432.618,993.951,405.161,979.594,379.331,962.621z"
      />
    </Icon>
  );
});

export const SharePointIconMetadata = {
  name: 'Share Point',
  importName: 'SharePointIcon',
  component: <SharePointIcon aria-label="Share Point Icon" />,
  tags: 'sharepoint, microsoft, logo, brand, company, org, organization, version, control, vc',
};
